import ArtInfo from "../../component-lib/art/ArtInfo";
import {
  cafe,
  canoeChannel,
  george,
  robson,
  templeton,
  columbia,
} from "../../assets/art/ArtPieces";

export const featuredArt: Array<ArtInfo> = [
  templeton,
  robson,
  columbia,
  george,
  cafe,
  canoeChannel,
];
