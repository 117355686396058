import Medium from "./Media";

class ArtInfo {
  constructor(
    public title: string,
    public description: string,
    public image: string,
    public medium: Medium,
    public dimensions?: string,
    public link?: string,
    public previewImage?: string
  ) {}

  separator = " - ";

  formatOneline(): string {
    let dimensionString = this.dimensions
      ? `${this.separator}${this.dimensions}`
      : "";
    return `${this.title}${dimensionString}${this.separator}${this.medium}`;
  }
}

export default ArtInfo;
