import "../../scss/styles.scss";
import "./SiteFooter.scss";

export default function SiteFooter() {
  return (
    <div className="SiteFooter">
      <div className="item">&copy; Art by Gabrielle Madden 2022</div>
    </div>
  );
}
