import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./CrossNav.scss";

function CrossNav() {
  return (
    <>
      <Navbar collapseOnSelect bg="primary" expand="md" sticky="top">
        <Container fluid>
          <Navbar.Brand href="#">Art by Gabrielle Madden</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-md-0" navbarScroll>
              <Nav.Link href="#/">Home</Nav.Link>
              <Nav.Link href="#/featured">Featured</Nav.Link>
              <NavDropdown title="Gallery" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#/gallery/paintings">
                  Paintings and Landscapes
                </NavDropdown.Item>
                <NavDropdown.Item href="#/gallery/original-art">
                  Original Art
                </NavDropdown.Item>
                <NavDropdown.Item href="#/gallery/portraits">
                  Portraits
                </NavDropdown.Item>
                <NavDropdown.Item href="#/gallery/pet-portraits">
                  Pets
                </NavDropdown.Item>
                <NavDropdown.Item href="#/gallery/fan-art">
                  Fan Art
                </NavDropdown.Item>
                <NavDropdown.Item href="#/gallery/graphic-art">
                  Graphic Art
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#/about">About</Nav.Link>
              <Nav.Link href="#/inquiries">Inquiries</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default CrossNav;
