import "./FeaturedView.scss";
import ArtCard from "../../component-lib/art/art-card/ArtCard";
import PageHeader from "../../component-lib/page-header/PageHeader";
import { featuredArt } from "./FeaturedArt";
import { featuredInfo } from "../PageInfo";
import classNameByIdx from "../../layout/classNameByIdx";

export default function FeaturedView() {
  return (
    <div className="FeaturedView">
      {PageHeader(
        featuredInfo.title,
        featuredInfo.description,
        featuredInfo.headerImage
      )}

      <div className="featuredBody">
        {featuredArt.map((work, i) => (
          <div className={classNameByIdx(i)} key={work.title}>
            {" "}
            {ArtCard(work)}{" "}
          </div>
        ))}
      </div>
    </div>
  );
}
