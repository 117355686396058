import "./AboutView.scss";
import self from "../../assets/art/digital/sELF.png";
import PageHeader from "../../component-lib/page-header/PageHeader";
import { aboutInfo } from "../PageInfo";
import { Col, Container, Row } from "react-bootstrap";

let newBio = (
  <>
    Gabrielle was born in the small country town of Carlisle, Ontario, a town
    with a population below 2000. As there wasn’t a whole lot to do in such a
    place, Gabrielle had a lot of spare time on her hands growing up and would
    frequently bother her mother with requests such as “I’m bored, can we make
    playdough?” and “None of my friends can play, can we make cookies?”. One of
    her mother’s brilliant solutions to get Gabrielle’s hands busy was to give
    her a pencil and paper. “Draw something!” she would say, before getting back
    to the dishes, or vacuuming, or whatever chore she was protecting her dear
    children from having the responsibility of doing.
    <br />
    <br />
    Gabrielle ended up looking to tutorials on the internet that would help her
    draw in an anime style. She found this style to be extremely satisfying and
    wound up spending several hours every day dedicated to creating original
    characters.
    <br />
    <br />
    Eventually, Gabrielle’s art started to improve. Through artist social media
    sites she was exposed to digital art and began to delve into that space,
    where she started doing online commissions.
    <br />
    <br />
    Many family and friends have since requested art of all kinds from
    Gabrielle. She has had the opportunity to work on things such as pet
    portraits, murals, tattoo designs, decal stickers for aircraft, and much,
    much more. The art charades still have not stopped to this day.
    <br />
    <br />
    Gabrielle has always had quite the indecisive streak. Upon completion of
    high school, she was too worried about “stability” or some nonsense and
    decided to pursue engineering. On this path, she discovered a love for
    computers and writing code.
    <br />
    <br />
    One day, Gabrielle hopes to have produced her own video game using the
    various skills she has acquired over the decades of her life. She figured
    she would start by designing a portfolio website from scratch and see where
    that takes her.
  </>
);

export default function AboutView() {
  return (
    <div className="AboutView">
      {PageHeader(
        aboutInfo.title,
        aboutInfo.description,
        aboutInfo.headerImage
      )}

      <Container>
        <Row>
          <Col md>{newBio}</Col>
          <Col md>
            <img className="portrait" src={self} alt="Self Portrait" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
