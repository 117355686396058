import PageHeader from "../../../component-lib/page-header/PageHeader";
import { PageInfo } from "../../PageInfo";
import ArtInfo from "../../../component-lib/art/ArtInfo";
import ArtPreview from "../previews/ArtPreview";
import "../GalleryView.scss";

export default function CategoryView(
  artList: Array<ArtInfo>,
  pageInfo: PageInfo
) {
  return (
    <div className="CategoryView">
      {PageHeader(pageInfo.title, pageInfo.description, pageInfo.headerImage)}

      <div className="section">
        {artList.map((artWork) => (
          <span className="responsive-half-width" key={artWork.title}>
            {ArtPreview(artWork)}
          </span>
        ))}
      </div>
    </div>
  );
}
