enum Medium {
  acrylic = "acrylic paint",
  oil = "oil paint",
  watercolour = "watercolour paint",
  marker = "copic marker",
  mixed = "mixed media",
  digital = "digital",
  graphite = "graphite",
  colouredPencils = "coloured pencils",
}

export default Medium;
