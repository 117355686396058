import PageHeader from "../../component-lib/page-header/PageHeader";
import {
  fanartInfo,
  galleryInfo,
  graphicArtInfo,
  originalsInfo,
  paintingsInfo,
  petsInfo,
  portraitsInfo,
} from "../PageInfo";
import "./GalleryView.scss";
import CategoryPreview from "./previews/CategoryPreview";

export default function GalleryView() {
  const galleryPages = [
    paintingsInfo,
    petsInfo,
    portraitsInfo,
    graphicArtInfo,
    fanartInfo,
    originalsInfo,
  ];

  return (
    <div className="GalleryView">
      {PageHeader(
        galleryInfo.title,
        galleryInfo.description,
        galleryInfo.headerImage
      )}

      <div className="section">
        {galleryPages.map((page) => (
          <span className="responsive-half-width" key={page.title}>
            {CategoryPreview(page)}
          </span>
        ))}
      </div>
    </div>
  );
}
