import { Link, To } from "react-router-dom";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import "../../../scss/styles.scss";
import "./ZiggyCard.scss";

export default function ZiggyCard(
  title: string,
  desc: any,
  image: string,
  link: To
) {
  return (
    <div className="ZiggyCard">
      <Container>
        <Link to={link}>
          <Card border="0" bg="info">
            <div className="overlay bg-light"></div>
            <Card.Body>
              <Row>
                <Col md>
                  <Image fluid rounded src={image} alt="bannerArt"></Image>
                </Col>
                <Col md className="my-auto">
                  <Card.Title>
                    <h1>{title}</h1>
                  </Card.Title>
                  <Card.Text>{desc}</Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Link>
      </Container>
    </div>
  );
}
