import PageHeader from "../../component-lib/page-header/PageHeader";
import { inquiriesInfo } from "../PageInfo";
import "./InquiriesView.scss";

export default function InquiriesView() {
  return (
    <div className="InquiriesView">
      {PageHeader(
        inquiriesInfo.title,
        inquiriesInfo.description,
        inquiriesInfo.headerImage
      )}

      <div className="thankyou">
        <h1>Thanks for your Interest!</h1>
        <p>
          Please fill out the Google form below. I will respond to your provided
          email as soon as possible.
        </p>
      </div>
      <div className="contact">
        <iframe
          title="Inquiries Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfRV1rYqjBSeAO4G9wavgfnNgG0BymC9aC09tx-JaO96FXVMA/viewform?embedded=true"
          width="100%"
          height="666"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}
