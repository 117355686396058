import { Route, Routes } from "react-router-dom";
import AboutView from "./pages/about/AboutView";
import HomeView from "./pages/home/HomeView";
import SiteFooter from "./layout/site-footer/SiteFooter";
import FeaturedView from "./pages/featured/FeaturedView";
import {
  aboutInfo,
  featuredInfo,
  inquiriesInfo,
  galleryInfo,
  paintingsInfo,
  portraitsInfo,
  petsInfo,
  originalsInfo,
  fanartInfo,
  graphicArtInfo,
} from "./pages/PageInfo";
import InquiriesView from "./pages/inquiries/InquiriesView";
import GalleryView from "./pages/gallery/GalleryView";
import CategoryView from "./pages/gallery/cateories/CategoryView";
import {
  paintingsAndLandscapes,
  portraits,
  pets,
  fanarts,
  graphicArts,
  originals,
} from "./pages/gallery/cateories/Categories";
import CrossNav from "./layout/cross-nav/CrossNav";

export default function App() {
  return (
    <div className="App">
      <CrossNav />
      <Routes>
        <Route path="/*" element={<HomeView />} />
        <Route
          path={featuredInfo.route.toString()}
          element={<FeaturedView />}
        />
        <Route path={aboutInfo.route.toString()} element={<AboutView />} />
        <Route
          path={inquiriesInfo.route.toString()}
          element={<InquiriesView />}
        />
        <Route path={galleryInfo.route.toString()} element={<GalleryView />} />
        <Route
          path={paintingsInfo.route.toString()}
          element={CategoryView(paintingsAndLandscapes, paintingsInfo)}
        />
        <Route
          path={portraitsInfo.route.toString()}
          element={CategoryView(portraits, portraitsInfo)}
        />
        <Route
          path={petsInfo.route.toString()}
          element={CategoryView(pets, petsInfo)}
        />
        <Route
          path={originalsInfo.route.toString()}
          element={CategoryView(originals, originalsInfo)}
        />
        <Route
          path={fanartInfo.route.toString()}
          element={CategoryView(fanarts, fanartInfo)}
        />
        <Route
          path={graphicArtInfo.route.toString()}
          element={CategoryView(graphicArts, graphicArtInfo)}
        />
      </Routes>
      <SiteFooter />
    </div>
  );
}
