import "../../scss/styles.scss";
import "./PageHeader.scss";
import { Container } from "react-bootstrap";

export default function PageHeader(
  header: string,
  content: any,
  image: string
) {
  return (
    <div className="PageHeader">
      <Container className="py-5">
        <h1 className="py-5">{header}</h1>
        <h3 className="py-3">{content}</h3>
      </Container>
      <div className="header-container">
        <div className="backdrop">
          <img src={image} alt="bannerArt"></img>
          <div className="overlay"></div>
        </div>
      </div>
    </div>
  );
}
