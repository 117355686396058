import { To } from "react-router-dom";
import LinkButton from "../component-lib/button/LinkButton";

import angel from "../assets/art/original/angel.png";
import robson from "../assets/art/paintings/robson_cropped.png";
import templeton from "../assets/art/paintings/templeton_cropped.png";
import constella from "../assets/art/original/constella.png";
import viktor from "../assets/art/fanart/viktor.jpeg";
import max from "../assets/art/pets/max.jpg";
import { self, feelin, ranch } from "../assets/art/ArtPieces";

export class PageInfo {
  constructor(
    public title: string,
    public description: string,
    public route: To,
    public headerImage: string
  ) {}
}

export const homeInfo = new PageInfo(
  "Home",
  "Explore the many artworks I have created over the years.",
  "/",
  angel
);

export const featuredInfo = new PageInfo(
  "Featured",
  "Learn more about pieces hand-selected from my best works to date.",
  "/featured",
  robson
);

export const galleryInfo = new PageInfo(
  "Gallery",
  "Explore a variety of my works in various media.",
  "/gallery",
  ranch.image
);

export const paintingsInfo = new PageInfo(
  "Paintings & Landscapes",
  "For that empty spot on your wall.",
  "/gallery/paintings",
  templeton
);

export const originalsInfo = new PageInfo(
  "Original Art",
  "Purely fictitious art that came straight from my head.",
  "/gallery/original-art",
  constella
);

export const portraitsInfo = new PageInfo(
  "Portraits",
  "For your loved ones.",
  "/gallery/portraits",
  self.image
);

export const petsInfo = new PageInfo(
  "Pets",
  "For your loved ones - the furry ones, that is.",
  "/gallery/pet-portraits",
  max
);

export const fanartInfo = new PageInfo(
  "Fan Art",
  "Favourite characters from the media.",
  "/gallery/fan-art",
  viktor
);

export const graphicArtInfo = new PageInfo(
  "Graphics & Concepts",
  "For businesses and projects.",
  "/gallery/graphic-art",
  feelin.image
);

export const newsInfo = new PageInfo(
  "News",
  "A place for adventures and reflections.",
  "/news",
  robson
);

export const aboutInfo = new PageInfo(
  "About the Artist",
  "",
  "/about",
  self.image
);

export const inquiriesInfo = new PageInfo(
  "Inquiries",
  "Contact me for a quote on custom artwork.",
  "/inquiries",
  templeton
);

export function pageInfoToLinkButton(pageInfo: PageInfo) {
  return <>{LinkButton(pageInfo.title.toUpperCase(), pageInfo.route)}</>;
}

export const mainPages: Array<PageInfo> = [
  homeInfo,
  featuredInfo,
  galleryInfo,
  // newsInfo,
  aboutInfo,
  inquiriesInfo,
];
