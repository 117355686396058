import PageHeader from "../../component-lib/page-header/PageHeader";
import ZiggyCard from "./ziggy/ZiggyCard";
import {
  featuredInfo,
  galleryInfo,
  homeInfo,
  inquiriesInfo,
} from "../PageInfo";
import classNameByIdx from "../../layout/classNameByIdx";
const homeLinks = [featuredInfo, galleryInfo, inquiriesInfo];

export default function HomeView() {
  return (
    <div className="HomeView">
      {PageHeader(
        "Art by Gabrielle Madden",
        "Welcome to my portfolio. Each piece has been crafted with love and care. Enjoy!",
        homeInfo.headerImage
      )}

      {homeLinks.map((link, i) => (
        <div className={classNameByIdx(i)} key={link.title}>
          {ZiggyCard(
            link.title,
            link.description,
            link.headerImage,
            link.route
          )}
        </div>
      ))}
    </div>
  );
}
