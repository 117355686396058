import "./Preview.scss";
import { Link } from "react-router-dom";
import { PageInfo } from "../../PageInfo";

export default function CategoryPreview(page: PageInfo) {
  return (
    <div className="Preview">
      <img src={page.headerImage} alt="category art"></img>

      <Link to={page.route}>
        <div className="highlight"></div>
        <div className="overlay"></div>

        <div className="contents">
          <h1>{page.title}</h1>
          {page.description}
        </div>
      </Link>
    </div>
  );
}
