import ArtInfo from "../../../component-lib/art/ArtInfo";
import {
  canoeChannel,
  columbia,
  george,
  robson,
  templeton,
  nala,
  zeusAndTrakr,
  fozz,
  max,
  jack,
  coal,
  ace,
  mog,
  karmen,
  brothers,
  mom,
  claire,
  billyJ,
  chaus,
  elihug,
  harley,
  jonsnow,
  tommyshelby,
  avengers,
  guardians,
  merunning,
  tomelf,
  self,
  thor,
  thor1,
  wandering,
  study,
  zelda,
  viktor,
  vi,
  sailorM,
  calcifer,
  howl,
  annora,
  strange,
  elsa,
  geralt,
  tanjiro,
  rebecca,
  jon,
  tighnari,
  beachbods1,
  beachbods3,
  beachbods2,
  bmw,
  dundurncockhouse,
  dundurngate,
  juliaSchool,
  fallforest,
  georgiansmall1,
  georgiansmall2,
  pinkvenice,
  knightstable,
  hydrangeas,
  airmonsters,
  amygab,
  angel,
  aurora,
  blanket1,
  bodysuit,
  bodysuitfull,
  constella,
  coporrobber,
  corner,
  covid,
  curse,
  devilsmmistress,
  dragon,
  enemy,
  faint,
  fall,
  forestElf,
  four,
  foxzenithEffect,
  gabalan,
  gaberin,
  gabmargo,
  halloween,
  handsupsol,
  hardened,
  howmuchyawant,
  kat,
  lasalle,
  levitating,
  lyre,
  magazine,
  ny2020,
  ontheway,
  orangeelf,
  piano_hall,
  pink,
  plan,
  princess,
  purple,
  ranch,
  rebel,
  recklessboi1,
  red,
  seamonster,
  siege,
  sniff1,
  space,
  waterelf,
  widow,
  wizardnerd,
  ancientgolembg,
  emperorpenguinbg,
  feelin,
  flyingpenguinbg,
  frostpenguinbg,
  hooligan,
  kingshroombg,
  knuckles4,
  pigs,
  ripper,
  save_me,
  shuptees1,
  shuptees2,
  shuptees3,
  shuptees4,
  underwaterpenguinbg,
  wychwood,
  athena,
  briggs,
  banff,
} from "../../../assets/art/ArtPieces";

export const paintingsAndLandscapes: Array<ArtInfo> = [
  templeton,
  robson,
  columbia,
  george,
  canoeChannel,
  georgiansmall1,
  georgiansmall2,
  banff,
  pinkvenice,
  knightstable,
  lasalle,
  beachbods1,
  beachbods2,
  beachbods3,
  hydrangeas,
  dundurncockhouse,
  dundurngate,
  bmw,
  juliaSchool,
  fallforest,
];

export const portraits: Array<ArtInfo> = [
  elihug,
  brothers,
  mom,
  claire,
  chaus,
  merunning,
  self,
  tomelf,
  wandering,
  study,
];

export const fanarts: Array<ArtInfo> = [
  vi,
  viktor,
  zelda,
  sailorM,
  tanjiro,
  rebecca,
  annora,
  harley,
  avengers,
  guardians,
  calcifer,
  howl,
  jonsnow,
  jon,
  tommyshelby,
  billyJ,
  geralt,
  strange,
  thor,
  thor1,
  elsa,
  tighnari,
];

export const pets: Array<ArtInfo> = [
  zeusAndTrakr,
  athena,
  nala,
  max,
  jack,
  briggs,
  fozz,
  coal,
  pigs,
  ace,
  mog,
  karmen,
];

export const originals: Array<ArtInfo> = [
  ranch,
  angel,
  bodysuitfull,
  constella,
  corner,
  covid,
  curse,
  devilsmmistress,
  dragon,
  four,
  forestElf,
  halloween,
  hardened,
  levitating,
  lyre,
  magazine,
  ny2020,
  ontheway,
  piano_hall,
  faint,
  orangeelf,
  pink,
  princess,
  red,
  purple,
  kat,
  fall,
  sniff1,
  widow,
  siege,
  space,
  waterelf,
  aurora,
  blanket1,
];

export const graphicArts: Array<ArtInfo> = [
  underwaterpenguinbg,
  kingshroombg,
  ancientgolembg,
  emperorpenguinbg,
  knuckles4,
  ripper,
  save_me,
  feelin,
  shuptees1,
  shuptees2,
  shuptees3,
  shuptees4,
  wychwood,
  hooligan,
  airmonsters,
  seamonster,
  foxzenithEffect,
  enemy,
  flyingpenguinbg,
  frostpenguinbg,
  bodysuit,
  wizardnerd,
];

export const stylized: Array<ArtInfo> = [
  amygab,
  gabalan,
  gaberin,
  gabmargo,
  howmuchyawant,
  coporrobber,
  plan,
  rebel,
  recklessboi1,
  handsupsol,
];
