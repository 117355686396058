import ArtInfo from "../ArtInfo";
import "./ArtCard.scss";
import Card from "react-bootstrap/esm/Card";
import { Image } from "react-bootstrap";

export default function ArtCard(info: ArtInfo) {
  return (
    <div className="ArtCard">
      <Card className="bg-info border-0 p-3">
        <Card.Title>
          <h1>{info.title}</h1>
        </Card.Title>
        <hr></hr>
        <Card.Body>
          <Image fluid rounded src={info.image} alt={info.title}></Image>
          <div className="caption">
            {info.formatOneline()}
            <hr />
          </div>
          {info.description}
        </Card.Body>
      </Card>
    </div>
  );
}
