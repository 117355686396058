import templetonImage from "./paintings/templeton_gallery.png";
import templetonCropped from "./paintings/templeton_cropped.png";
import canoeChannelImage from "./paintings/canoe_channel_gallery.jpeg";
import canoeChannelCropped from "./paintings/canoe_channel_cropped.jpeg";
import robsonImage from "./paintings/robson_gallery.png";
import robsonCropped from "./paintings/robson_cropped.png";
import columbiaImage from "./paintings/columbiaRiver_gallery.jpg";
import columbiaCropped from "./paintings/columbiaRiver_cropped.jpg";
import georgeImage from "./paintings/george_gallery.png";
import georgeCropped from "./paintings/george_cropped.png";
import combsImage from "./digital/combs_gallery.png";
import cafeImage from "./paintings/cafe_mural.png";
import beachbods1Image from "./paintings/beachbods1.jpg";
import beachbods2Image from "./paintings/beachbods2.jpg";
import beachbods3Image from "./paintings/beachbods3.jpg";
import bmwImage from "./paintings/bmwpaint.jpg";
import dundurncockhouseImage from "./paintings/dundurncockhouse.jpg";
import dundurngateImage from "./paintings/dundurngate.jpg";
import juliaschoolImage from "./paintings/juliaschool.jpg";
import fallforestImage from "./paintings/fallforest.jpg";
import georgesmall1Image from "./paintings/georgiansmall1.jpg";
import georgesmall2Image from "./paintings/georgiansmall2.jpg";
import knightstableImage from "./paintings/knightstable.jpg";
import pinkveniceImage from "./paintings/pinkvenice.jpg";
import hydrangeasImage from "./paintings/hydrangeas.png";
import banffImage from "./paintings/banffMinis.jpg";

import nalaImage from "./pets/nala.png";
import zeusTrakrImage from "./pets/zeusAndTrakr.png";
import athenaImage from "./pets/athena.png";
import fozzImage from "./pets/fozzie.jpg";
import maxImage from "./pets/max.jpg";
import jackImage from "./pets/jack.jpg";
import coalImage from "./pets/coal.png";
import aceImage from "./pets/ace.jpg";
import mogImage from "./pets/mog.jpg";
import karmenImage from "./pets/clover.jpg";
import caramelImage from "./pets/caramelcat.jpg";
import birdImage from "./pets/artbird.jpg";
import briggsImage from "./pets/briggs.png";

import brothersImage from "./portraits/brothers.jpg";
import amomImage from "./portraits/amom.jpg";
import claireImage from "./portraits/claire.png";
import chausImage from "./portraits/chaus.jpg";
import elihugImage from "./portraits/elihug.jpg";
import merunningImage from "./portraits/merunning.jpg";
import selfImage from "./portraits/sELF.png";
import thomelfImage from "./portraits/thomelf.png";
import wanderingImage from "./portraits/wandering.png";
import studyImage from "./portraits/study.png";

import billyjImage from "./fanart/billyJ.jpg";
import harleyImage from "./fanart/harley.jpg";
import jonsnowImage from "./fanart/jonsnow.png";
import jonImage from "./fanart/jon.jpg";
import peakyImage from "./fanart/peaky.png";
import avengersImage from "./fanart/avengers.jpg";
import guardiansImage from "./fanart/guardians.jpg";
import thorImage from "./fanart/thor.png";
import thor1Image from "./fanart/thor1.png";
import zeldaImage from "./fanart/zelda.png";
import viktorImage from "./fanart/viktor.jpeg";
import tanjiroImage from "./fanart/tanjiro.png";
import viImage from "./fanart/vi.png";
import sailormImage from "./fanart/sailormoon2.png";
import rebeccaImage from "./fanart/rebecca.png";
import oceanImage from "./fanart/oceanofsecrets.jpeg";
import howlImage from "./fanart/howl.jpg";
import geraltImage from "./fanart/geralt.png";
import elsaImage from "./fanart/elsa.png";
import strangeImage from "./fanart/drStrange.png";
import calciferImage from "./fanart/calcifer.jpg";
import annoraImage from "./fanart/annoraYuming.png";
import tigImage from "./fanart/tighnari.png";

import airmonstersImage from "./original/airmonsters.png";
import amygabImage from "./original/amy&gab.png";
import angelImage from "./original/angel.png";
import auroraImage from "./original/aurora.png";
import blanket1Image from "./original/blanket1.png";
import bodysuitImage from "./original/bodysuit.jpeg";
import bodysuitfullImage from "./original/bodysuitfull.png";
import chibiImage from "./original/chibi.png";
import constellaImage from "./original/constella.png";
import coporrobberImage from "./original/coporrobber.jpeg";
import cornerImage from "./original/corner.png";
import covidImage from "./original/covid.jpg";
import curseImage from "./original/curse.png";
import devilsmmistressImage from "./original/devilsmmistress.png";
import dragonImage from "./original/dragon.jpg";
import enemyImage from "./original/enemy.png";
import faintImage from "./original/faint.png";
import fallImage from "./original/fall.png";
import flatheadImage from "./original/flathead.jpg";
import forestElfImage from "./original/forestElf.png";
import fourImage from "./original/four.png";
import foxzenithEffectImage from "./original/fox&zenithEffect.png";
import gabalanImage from "./original/gab&alan.jpg";
import gaberinImage from "./original/gab&erin.jpg";
import gabmargoImage from "./original/gab&margo.jpg";
import halloweenImage from "./original/halloween.png";
import handsupsolImage from "./original/handsupsol.png";
import hardenedImage from "./original/hardened.jpg";
import howmuchyawantImage from "./original/howmuchyawant.jpg";
import katImage from "./original/kat.png";
import lasalleImage from "./original/lasalle.jpg";
import levitatingImage from "./original/levitating.png";
import lyreImage from "./original/lyre.jpeg";
import magazineImage from "./original/magazine.png";
import masqueImage from "./original/masque.jpeg";
import mooneyesImage from "./original/mooneyes.png";
import natalieCommissionImage from "./original/natalieCommission.png";
import ny2020Image from "./original/ny2020.png";
import onthewayImage from "./original/ontheway.jpg";
import orangeelfImage from "./original/orangeelf.jpeg";
import piano_hallImage from "./original/piano_hall.jpeg";
import pinkImage from "./original/pink.jpg";
import planImage from "./original/plan.png";
import princessImage from "./original/princess.png";
import purpleImage from "./original/purple.png";
import ranchImage from "./original/ranch.png";
import rebelImage from "./original/rebel.jpeg";
import recklessboi1Image from "./original/recklessboi1.png";
import redImage from "./original/red.jpeg";
import seamonsterImage from "./original/seamonster.png";
import siegeImage from "./original/siege.png";
import sniff1Image from "./original/sniff1.png";
import spaceImage from "./original/space.jpg";
import sportsImage from "./original/sports.png";
import waterelfImage from "./original/waterelf.png";
import widowImage from "./original/widow.png";
import wizardnerdImage from "./original/wizardnerd.jpg";

import ancientgolembgImage from "./graphic/ancient-golem-bg.png";
import emperorpenguinbgImage from "./graphic/emperor-penguin-bg.png";
import feelinImage from "./graphic/feelin.jpg";
import flyingpenguinbgImage from "./graphic/flying-penguin-bg.png";
import frostpenguinbgImage from "./graphic/frost-penguin-bg.png";
import hooliganImage from "./graphic/hooligan.jpeg";
import kingshroombgImage from "./graphic/king-shroom-bg.png";
import knuckles4Image from "./graphic/knuckles4.jpeg";
import lightninggolembgImage from "./graphic/lightning-golem-bg.png";
import magepenguinbgImage from "./graphic/mage-penguin-bg.png";
import pigsImage from "./graphic/pigs.png";
import ripperImage from "./graphic/ripper.png";
import save_meImage from "./graphic/save_me.png";
import shuptees1Image from "./graphic/shuptees1.png";
import shuptees2Image from "./graphic/shuptees2.png";
import shuptees3Image from "./graphic/shuptees3.png";
import shuptees4Image from "./graphic/shuptees4.png";
import spiritpenguinbgImage from "./graphic/spirit-penguin-bg.png";
import underwaterpenguinbgImage from "./graphic/underwater-penguin-bg.png";
import warriorpenguinbgImage from "./graphic/warrior-penguin-bg.png";
import wychwoodImage from "./graphic/wychwood.png";

import ArtInfo from "../../component-lib/art/ArtInfo";
import Medium from "../../component-lib/art/Media";

export const templeton = new ArtInfo(
  "Templeton Lake",
  "This piece takes us to stunning lanscape of British Columbia during Fall. The air is beginning to turn crisp. It's the September days in which the larch trees begin to shift to a yellow hue. With the help of the afternoon sun, they radiate auric gold. This ultimate treasure is the gift that Templeton Lake bestows upon the devoted explorers that make their journey to witness it.",
  templetonImage,
  Medium.acrylic,
  '48x72"',
  undefined,
  templetonCropped
);

export const robson = new ArtInfo(
  "Mount Robson",
  "Driving along the road through Jasper, a monolith grows in the distance. Its size becomes more incomprehensible with every kilometer of the approach. The highest peak in Canada, its summmit exists in an eternal winter. This piece was created for a dear client who has travelled to Mount Robson many times and is continuously dazzled by its grandeur. Emphasis on the sheer depth was an important quality for the painting to depict.",
  robsonImage,
  Medium.acrylic,
  '36x48"',
  undefined,
  robsonCropped
);

export const columbia = new ArtInfo(
  "Columbia River",
  "The sun graces the banks of the Columbia River, illuminating the larch needles whose colours are just beginning to turn yellow with the season. A pair of fortunate hikers has stumbled upon this moment in time, capturing it with a camera to be immortalized on canvas.",
  columbiaImage,
  Medium.acrylic,
  '48x36"',
  undefined,
  columbiaCropped
);

export const george = new ArtInfo(
  "Georgian Sunset",
  "This piece is inspired by iconic Georgian Bay with its windswept pines decorating the thousands of granite islands speckling Lake Huron. After an agitating storm rocks the bay, the clouds break in time for sunset. Golden light spreads over the prevailing landscape, signalling time for rest.",
  georgeImage,
  Medium.acrylic,
  '36x48"',
  undefined,
  georgeCropped
);

export const canoeChannel = new ArtInfo(
  "Dusk on Canoe Channel",
  "On Canoe Channel, two Dark and Stormies into the evening, the Madden family gathers on the deck to watch the Summer dusk settle. A loon calls. Frogs begin to chirp. The dusk brings calm waters to cradle the familiar pink granite islands in the distance.",
  canoeChannelImage,
  Medium.acrylic,
  '15x60"',
  undefined,
  canoeChannelCropped
);

export const cafe = new ArtInfo(
  "Mural of The Crusaders",
  "This mural resides in the cafeteria of St. Mary Catholic Secondary School, where Gabrielle spent her teenage years. This photo was taken upon completion of the mural, having spend time on it every day for 5 months with a small team of helpers.",
  cafeImage,
  Medium.acrylic,
  "10x30'"
);

export const beachbods1 = new ArtInfo(
  "Sunday Morning",
  "Depicting the start of a day at the beach, this painting is the first of a series of three that were created for a gym.",
  beachbods1Image,
  Medium.acrylic,
  '40x30"'
);
export const beachbods2 = new ArtInfo(
  "Sunday Noon",
  "Depicting a relaxing time at the picnic table, this painting is the second of a series of three that were created for a gym.",
  beachbods2Image,
  Medium.acrylic,
  '40x30"'
);

export const beachbods3 = new ArtInfo(
  "Sunday Afternoon",
  "Depicting a group of friends ready to hit the waves, this painting is the third of a series of three that were created for a gym.",
  beachbods3Image,
  Medium.acrylic,
  '40x30"'
);

export const bmw = new ArtInfo(
  "BMW Focus",
  "A Christmas gift painted for a proud BMW owner.",
  bmwImage,
  Medium.acrylic,
  '12x16"'
);

export const dundurncockhouse = new ArtInfo(
  "Dundurn Cockpit Theatre",
  "A black and white architectural study of the Cockpit Theatre at Dundurn Castle in Hamilton.",
  dundurncockhouseImage,
  Medium.graphite,
  '12x9"'
);

export const dundurngate = new ArtInfo(
  "Dundurn Gate",
  "A watercolour architectural study of the gate at Dundurn Castle in Hamilton.",
  dundurngateImage,
  Medium.watercolour,
  '12x9"'
);

export const fallforest = new ArtInfo(
  "Fall Forest",
  "A painting inspired by the small lakes and ponds dotting Georgian Bay during Fall.",
  fallforestImage,
  Medium.acrylic,
  '16x12"'
);

export const georgiansmall1 = new ArtInfo(
  "Georgian Island West",
  "One of two paintings created for a Georgian Bay set.",
  georgesmall1Image,
  Medium.watercolour,
  '12x9"'
);

export const georgiansmall2 = new ArtInfo(
  "Georgian Island East",
  "One of two paintings created for a Georgian Bay set.",
  georgesmall2Image,
  Medium.watercolour,
  '12x9"'
);

export const juliaSchool = new ArtInfo(
  "St. Julia School",
  "A commission of St Julia Catholic Elementary School.",
  juliaschoolImage,
  Medium.graphite,
  '8x11.5"'
);

export const pinkvenice = new ArtInfo(
  "Pink Venice",
  "A stress-release session resulted in a pink sunset painting.",
  pinkveniceImage,
  Medium.acrylic,
  '5x7"'
);

export const knightstable = new ArtInfo(
  "Knight's Table",
  "A still life of subject matter I assembled using various items around my house. The items includes a costume helm and Jon Snow sword as seen in the hit HBO series.",
  knightstableImage,
  Medium.acrylic,
  '16x20"'
);

export const hydrangeas = new ArtInfo(
  "Hydrangeas",
  "A painting for a neighbour of some hydrangeas using a warm palette.",
  hydrangeasImage,
  Medium.acrylic,
  '16x20"'
);

export const banff = new ArtInfo(
  "Banff Mini Series",
  "A painting series of imagery I took myself while traveling in Banff.",
  banffImage,
  Medium.oil,
  '4x4" * 4'
);

export const combs = new ArtInfo(
  "Dr. Combs' Lab",
  "An entry form the Ubisoft 2019 concept art contest. Given a letter from an apprentice describing eerie setting of his professor's lab, this concept was brought to life using digital materials and Photoshop techniques.",
  combsImage,
  Medium.digital
);

export const nala = new ArtInfo(
  "Nala",
  "A graphite portrait of a blue-nosed pitbull named Nala.",
  nalaImage,
  Medium.graphite,
  '12x9"'
);

export const zeusAndTrakr = new ArtInfo(
  "Zeus and Trakr",
  "A coloured pencil portrait of Zeus and Trakr, best friends.",
  zeusTrakrImage,
  Medium.colouredPencils,
  '14x11"'
);

export const athena = new ArtInfo(
  "Athena",
  "A portrait of a dog named Athena, with a young Athena pup portrait in the bottom right.",
  athenaImage,
  Medium.colouredPencils,
  '14x11"'
);

export const fozz = new ArtInfo(
  "Fozzie",
  "This dog is aptly named Fozzie. The commissioner wished for a portrait of their darling portrayed in today's time with a smaller section in the younger years.",
  fozzImage,
  Medium.graphite,
  '12x9"'
);

export const max = new ArtInfo(
  "Max",
  "A perfect mother's day gift takes the shape of a portrait of her dear companion, Max.",
  maxImage,
  Medium.graphite,
  '12x9"'
);

export const jack = new ArtInfo(
  "Jack",
  "A coloured portrait of Jack, a Shepard/Bernese mix brought into his family as a rescue.",
  jackImage,
  Medium.colouredPencils,
  '12x9"'
);

export const coal = new ArtInfo(
  "Snowse",
  "A birthday gift of the recipient's cherished black lab.",
  coalImage,
  Medium.digital,
  '12x9"'
);

export const ace = new ArtInfo(
  "Ace",
  "This painting is of a horse named Ace, dramatized with the addition of tribal paint and decorations in the mane.",
  aceImage,
  Medium.acrylic,
  '12x16"'
);

export const mog = new ArtInfo(
  "Mog",
  "A coloured pencil portrait of our cat of many nick-names. His most popular name happens to be Mog Mog, a name my British father kept saying until it stuck.",
  mogImage,
  Medium.colouredPencils,
  '8x11.5"'
);

export const karmen = new ArtInfo(
  "Karmen",
  'A coloured pencil portrait of lucky Karmen laying in the "rub my tummmy or else!" pose.',
  karmenImage,
  Medium.colouredPencils,
  '8x11.5"'
);

export const caramelCat = new ArtInfo(
  "Caramel",
  "A coloured pencil portrait of a family friend's cat.",
  caramelImage,
  Medium.colouredPencils,
  '8x11.5"'
);

export const bird = new ArtInfo(
  "Wings",
  "A wingspan study to get an idea of how feathers spread during flight.",
  birdImage,
  Medium.graphite,
  '8x11.5"'
);

export const briggs = new ArtInfo(
  "Briggs",
  "A portrait of a sheltie named Briggs.",
  briggsImage,
  Medium.colouredPencils,
  '12x9"'
);

export const brothers = new ArtInfo(
  "Mills Brothers",
  "A double portrait of two brothers wearing their career uniforms shortly after entering the workforce.",
  brothersImage,
  Medium.graphite,
  '12x9"'
);

export const mom = new ArtInfo(
  "Celebrate Mom",
  "A painting of a client's mother, given as a gift for her birthday.",
  amomImage,
  Medium.acrylic,
  '16x12"'
);

export const claire = new ArtInfo(
  "Claire",
  "A digital painting of my lifelong friend for a birthday gift.",
  claireImage,
  Medium.digital
);

export const billyJ = new ArtInfo(
  "Billy Joe",
  "A portrait of Green Day's lead singer, created as a figt for a friend.",
  billyjImage,
  Medium.graphite,
  '11.5x8"'
);

export const chaus = new ArtInfo(
  "Chau Family",
  "A drawing of a photo of the Chau faimly on a family vacation.",
  chausImage,
  Medium.graphite,
  '8x11.5"'
);

export const elihug = new ArtInfo(
  "Blissful Kiss",
  "A commission of a client's nephew given as a gift to the mother.",
  elihugImage,
  Medium.graphite,
  '12x9"'
);

export const harley = new ArtInfo(
  "Harley",
  "A portrait of Margot Robbie's Harley Quinn created using markers, watercolour paints, and coloured pencils.",
  harleyImage,
  Medium.mixed,
  '8x11.5"'
);

export const jonsnow = new ArtInfo(
  "Jon Snow - White Wolf",
  "Digital fan art of Game of Thrones' Jon Snow as seen in the HBO series.",
  jonsnowImage,
  Medium.digital
);

export const jon = new ArtInfo(
  "Jon Snow",
  "Traditional fan art of Game of Thrones' Jon Snow as seen in the HBO series.",
  jonImage,
  Medium.graphite,
  '12x9"'
);

export const tommyshelby = new ArtInfo(
  "Tommy Shelby",
  "Fan art of Cillian Murphy's Thomas Shelby as seen in Peaky Blinders.",
  peakyImage,
  Medium.digital
);

export const avengers = new ArtInfo(
  "Avengers",
  "Fan art of Marvel's Avengers.",
  avengersImage,
  Medium.colouredPencils,
  '8x11.5"'
);

export const guardians = new ArtInfo(
  "Guardians",
  "Fan art of Marvel's Guardians of the Galaxy.",
  guardiansImage,
  Medium.colouredPencils,
  '8x11.5"'
);

export const merunning = new ArtInfo(
  "Escape",
  'A self portrait inspired by the right section of "Aeneas\' Flight", a painting by Federico Barocci.',
  merunningImage,
  Medium.acrylic,
  '20x16"'
);

export const self = new ArtInfo(
  "Self Portrait",
  "A self portrait in semi-realistic elven style.",
  selfImage,
  Medium.digital
);

export const tomelf = new ArtInfo(
  "Elf portrait - Thomas",
  "Upon seeing my self portrait in an elven style, my mother requested that I do another for my brother.",
  thomelfImage,
  Medium.digital
);

export const thor = new ArtInfo(
  "Thor",
  "Fan art of Marvel's Thor.",
  thorImage,
  Medium.digital
);

export const thor1 = new ArtInfo(
  "Thor: Ragnarok",
  "Fan art of Marvel's Thor, without using reference images.",
  thor1Image,
  Medium.digital
);

export const wandering = new ArtInfo(
  "Wandering",
  "A study of Elle Fanning in an elven style.",
  wanderingImage,
  Medium.digital
);

export const study = new ArtInfo(
  "Study",
  "A study of a tumblr portrait in an elven style. Source: https://sylviagetyourheadouttheoven.tumblr.com/post/55095911337",
  studyImage,
  Medium.digital
);

export const zelda = new ArtInfo(
  "Zelda",
  "Zelda as seen in Breath of the Wild.",
  zeldaImage,
  Medium.digital
);

export const viktor = new ArtInfo(
  "Viktor",
  "Leage of Legends' Viktor as seen in Arcane.",
  viktorImage,
  Medium.digital
);

export const vi = new ArtInfo(
  "Vi",
  "Leage of Legends' Vi as seen in Arcane.",
  viImage,
  Medium.digital
);

export const sailorM = new ArtInfo(
  "Sailor Moon",
  "Sailor Moon in my style, drawn for a redraw trend on Instagram.",
  sailormImage,
  Medium.digital
);

export const tanjiro = new ArtInfo(
  "Tanjiro",
  "Tanjiro from the Demon Slayer anime.",
  tanjiroImage,
  Medium.digital
);

export const rebecca = new ArtInfo(
  "Rebecca",
  "Rebecca as seen in the Netflix show Cyberpunk: Edgerunners.",
  rebeccaImage,
  Medium.digital
);

export const oceanofsecrets = new ArtInfo(
  "Ocean of Secrets",
  "A watercolor entry for Sophie Chan's art contest.",
  oceanImage,
  Medium.watercolour,
  '12x9"'
);

export const howl = new ArtInfo(
  "Howl",
  "A small watercolour of Howl from the Studio Ghibli film called Howl's Moving Castle, a personal favourite.",
  howlImage,
  Medium.watercolour,
  '7x5"'
);

export const geralt = new ArtInfo(
  "Geralt",
  "Geralt of Rivia from the Witcher franchise in my style.",
  geraltImage,
  Medium.digital
);

export const elsa = new ArtInfo(
  "Elsa",
  "Elsa from Disney's Frozen in my style.",
  elsaImage,
  Medium.digital
);

export const strange = new ArtInfo(
  "Doctor Strange",
  "Marvel's Doctor Strange in my style.",
  strangeImage,
  Medium.digital
);

export const calcifer = new ArtInfo(
  "Calcifer",
  "Howl with his buddy Calcifer in a semi-realistic style.",
  calciferImage,
  Medium.digital
);

export const annora = new ArtInfo(
  "Annora",
  "A draw-this-in-your-style contest entry for yuming_art on Instagram.",
  annoraImage,
  Medium.digital
);

export const tighnari = new ArtInfo(
  "Tighnari",
  "A forest watcher from Genshin Impact.",
  tigImage,
  Medium.digital
);

export const airmonsters = new ArtInfo(
  "Bird Battle",
  "A concept piece of air-type monsters done for the DeFimons video game promotional artwork series.",
  airmonstersImage,
  Medium.digital
);

export const amygab = new ArtInfo(
  "Amy & Gab",
  "A gift for Amy, my university roommate of two years, in a chibi style.",
  amygabImage,
  Medium.digital
);

export const angel = new ArtInfo(
  "Angel Breath",
  "An original piece playing with expression, motion, and light. This was created from scratch using Adobe Photoshop.",
  angelImage,
  Medium.digital
);

export const aurora = new ArtInfo(
  "Aurora",
  "A \"Quar-ink-tine\" piece I created during the 2020 pandemic. Inspired by the prompt 'aurora'.",
  auroraImage,
  Medium.digital
);

export const blanket1 = new ArtInfo(
  "Blanket",
  "An idea that came to me randomly one day. Developed without using reference to test my inner knowledge of cloth folds and light.",
  blanket1Image,
  Medium.digital
);

export const bodysuit = new ArtInfo(
  "Bodysuit",
  "A concept piece taking inspiration from the work of concept artist Christophe Young.",
  bodysuitImage,
  Medium.digital
);

export const bodysuitfull = new ArtInfo(
  "Bodysuit Full",
  "A concept piece taking inspiration from the work of concept artist Christophe Young, with an added background.",
  bodysuitfullImage,
  Medium.digital
);

export const chibi = new ArtInfo(
  "Chibi Delight",
  "A chibi design developed for fun.",
  chibiImage,
  Medium.digital
);

export const constella = new ArtInfo(
  "Constella",
  "An attempt at the digital grey-scale technique where colour is applied after painting the entire piece using only shade tones.",
  constellaImage,
  Medium.digital
);

export const coporrobber = new ArtInfo(
  "Cops or Robbers?",
  "A character concept developed after exploring Toronto during a rainy night.",
  coporrobberImage,
  Medium.digital
);

export const corner = new ArtInfo(
  "Corner Store",
  "A \"Quar-ink-tine\" piece I created during the 2020 pandemic. Inspired by the prompt 'corner'.",
  cornerImage,
  Medium.digital
);

export const covid = new ArtInfo(
  "Covid Persona",
  "Covid personified. A character concept developed in the early months of the 2020 covid pandemic.",
  covidImage,
  Medium.digital
);

export const curse = new ArtInfo(
  "Curse",
  "A \"Quar-ink-tine\" piece I created during the 2020 pandemic. Inspired by the prompt 'curse'.",
  curseImage,
  Medium.digital
);

export const devilsmmistress = new ArtInfo(
  "Devil's Mistress",
  "A character concept I developed traditionally with a pencil an paper, but coloured digitally using the Ibis Paint mobile application.",
  devilsmmistressImage,
  Medium.mixed
);

export const dragon = new ArtInfo(
  "Traditional Dragon",
  "A copic illustration of a dragon created as a surprise going-away gift for a friend. I ended up keeping the original because I liked it so much.",
  dragonImage,
  Medium.marker
);

export const enemy = new ArtInfo(
  "Soldier of the Subconscious",
  "You wake up in a large loading area stacked high with shipping containers and boxes. The facility is heavily guarded. You're not sure why you're there, but you have seen the inner workings of this place and now know too much for the guards to let you leave peacefully. You have to find a way to escape. You peer around the side of the container you're hiding behind and see an armed six and a half foot man with bleached dreadlocks patrolling your escape route. Quietly you wait until he turns so you can sneak by. It's no use. You get caught. You spawn right back at the shipping container you initially woke up next to. You peer around it again. Sure enough, he is standing there at his post. You are stuck in a loop. Attempt escape; get caught; spawn behind the shipping container next to the man with the bleached dreadlocks; repeat; repeat; repeat. Eventually you wake up to your bedroom in the real world and decide you should draw this interesting guard at the level of detail you witnessed in your dreams.",
  enemyImage,
  Medium.digital
);

export const faint = new ArtInfo(
  "Faint",
  "A \"Quar-ink-tine\" piece I created during the 2020 pandemic. Inspired by the prompt 'faint'.",
  faintImage,
  Medium.digital
);

export const fall = new ArtInfo(
  "Fall",
  "A portrait created in the spirit of Fall starting.",
  fallImage,
  Medium.digital
);

export const flathead = new ArtInfo(
  "Fringe",
  "Some portrait practice.",
  flatheadImage,
  Medium.digital
);

export const forestElf = new ArtInfo(
  "Forest Elf",
  "Portrait practice while focusing on the dappled light effect seen in an area shrouded with trees.",
  forestElfImage,
  Medium.digital
);

export const four = new ArtInfo(
  "Painter's Gaze",
  "A painting done imitating the style of Maerel Hibadita known as innervalue on Instagram.",
  fourImage,
  Medium.digital
);

export const foxzenithEffect = new ArtInfo(
  "Fox & Zenith",
  "An illustration of my original character, Fox, exploring a hillcrest with his doberman companion Zenith.",
  foxzenithEffectImage,
  Medium.digital
);

export const gabalan = new ArtInfo(
  "Alan & Gab",
  "A Christmas gift for my partner.",
  gabalanImage,
  Medium.digital
);

export const gaberin = new ArtInfo(
  "Erin & Gab",
  "A Christmas gift for a friend.",
  gaberinImage,
  Medium.digital
);

export const gabmargo = new ArtInfo(
  "Margo & Gab",
  "A birthday gift for a friend.",
  gabmargoImage,
  Medium.watercolour
);

export const halloween = new ArtInfo(
  "Potion Master",
  "The final result of a Halloween inspired art tutorial I developed for an art-based social media platform.",
  halloweenImage,
  Medium.digital
);

export const handsupsol = new ArtInfo(
  "Hands Up",
  "A preliminary version of my original character Sol.",
  handsupsolImage,
  Medium.digital
);

export const hardened = new ArtInfo(
  "Frostbitten",
  "An original piece done inspired by the White Walkers of Game of Thrones.",
  hardenedImage,
  Medium.digital
);

export const howmuchyawant = new ArtInfo(
  "How Much D'Ya Want?",
  "A concept inspired by the prompt 'crystal'.",
  howmuchyawantImage,
  Medium.digital
);

export const kat = new ArtInfo(
  "Kat",
  "An original portrait done for some practice.",
  katImage,
  Medium.digital
);

export const lasalle = new ArtInfo(
  "Lasalle Marina Mural",
  "A mural I designed an executed while working at the Lasalle Park Marina. It can be seen on the portable office of the marina located next to the boat ramp in Lasalle Park, Burlington, Ontario.",
  lasalleImage,
  Medium.acrylic
);

export const levitating = new ArtInfo(
  "Levitating",
  "An original piece inspired by the works of Peter Mohrbacher.",
  levitatingImage,
  Medium.digital
);

export const lyre = new ArtInfo(
  "Lyre",
  "A portrait of a forest dweller delicately strumming his beloved lyre for all the creatures to hear.",
  lyreImage,
  Medium.digital
);

export const magazine = new ArtInfo(
  "Way Home Entertainment",
  "A \"Quar-ink-tine\" piece I created during the 2020 pandemic. Inspired by the prompt 'magazine'.",
  magazineImage,
  Medium.digital
);

export const masque = new ArtInfo(
  "Masque",
  "An anime portrait of a character given to me by an online friend.",
  masqueImage,
  Medium.digital
);

export const mooneyes = new ArtInfo(
  "Moon Eyes",
  "An \"Ink-tober\" piece I created. Inspired by the prompt 'full moon'.",
  mooneyesImage,
  Medium.digital
);

export const natalieCommission = new ArtInfo(
  "Witcher from Earth",
  "A commission of a client's partner wearing Witcher-inspired garb.",
  natalieCommissionImage,
  Medium.digital
);

export const ny2020 = new ArtInfo(
  "NYE 2020",
  "I spent the 2020 New Year's Eve with my friends on a zoom call since it was not appropriate at the time to meet up in person. We all dressed up and treated it like a real party with drinks and games. It felt like one of the first real steps into an isolative virtual future era where people begin to prefer to interact digitally. I wanted to draw the moment, taking it a step further with my friends on giant screens operating as the heads of domestic robots, enhancing the future feel with cold city lights.",
  ny2020Image,
  Medium.digital
);

export const ontheway = new ArtInfo(
  "On the Way",
  "A character design landing in a pastel-punk realm.",
  onthewayImage,
  Medium.marker
);

export const orangeelf = new ArtInfo(
  "Golden Hour Portrait",
  "An early portrait done as practice using techniques described in tutorials from art magazines.",
  orangeelfImage,
  Medium.digital
);

export const piano_hall = new ArtInfo(
  "Piano Hall",
  "An early piece I developed with the intention of using it as banner art for my YouTube channel.",
  piano_hallImage,
  Medium.digital
);

export const pink = new ArtInfo(
  "Pink",
  "A house-warming gift done for a friend who had painted all her walls pink.",
  pinkImage,
  Medium.acrylic
);

export const plan = new ArtInfo(
  "Adventure Plan",
  "A \"Quar-ink-tine\" piece I created during the 2020 pandemic. Inspired by the prompt 'plan'.",
  planImage,
  Medium.digital
);

export const princess = new ArtInfo(
  "High Elf Portrait",
  "Some more portrait practice.",
  princessImage,
  Medium.digital
);

export const purple = new ArtInfo(
  "Purple Gaze",
  "Some portrait practice, trying out techniques using the iPad application 'Procreate'.",
  purpleImage,
  Medium.digital
);

export const ranch = new ArtInfo(
  "Ranch",
  "A \"Quar-ink-tine\" piece I created during the 2020 pandemic. Inspired by the prompt 'ranch'.",
  ranchImage,
  Medium.digital
);

export const rebel = new ArtInfo(
  "Rebel",
  "A heavily stylized portrait of a punk with an attitude created to blow off some steam.",
  rebelImage,
  Medium.digital
);

export const recklessboi1 = new ArtInfo(
  "City Hopper",
  "A piece made to practice city scapes and characters inside them.",
  recklessboi1Image,
  Medium.digital
);

export const red = new ArtInfo(
  "Red Gaze",
  "A portrait idea I wanted to bring to life using a monochromatic palette.",
  redImage,
  Medium.digital
);

export const seamonster = new ArtInfo(
  "Sea Battle",
  "A concept piece of water-type monsters done for the DeFimons video game promotional artwork series.",
  seamonsterImage,
  Medium.digital
);

export const siege = new ArtInfo(
  "Siege",
  "A \"Quar-ink-tine\" piece I created during the 2020 pandemic. Inspired by the prompt 'siege'.",
  siegeImage,
  Medium.digital
);

export const sniff1 = new ArtInfo(
  "If I Have To",
  "A \"Quar-ink-tine\" piece I created during the 2020 pandemic. Inspired by the prompt 'sniff'. The portrait was originally performing a french inhale, but I decided it looked better without it. I was left with a portrait of someone mildly grimacing while rolling their eyes.",
  sniff1Image,
  Medium.digital
);

export const space = new ArtInfo(
  "Lost Tether",
  "An \"Ink-tober\" piece I created. Inspired by the prompt 'space'.",
  spaceImage,
  Medium.marker
);

export const sports = new ArtInfo(
  "Sports",
  "A concept design for an NFT collection that would feature variations on a motorcross racer.",
  sportsImage,
  Medium.digital
);

export const waterelf = new ArtInfo(
  "Water Elf",
  "A redraw of an early piece featuring a water elf. Done to compare the improvement in my style and skills over many years.",
  waterelfImage,
  Medium.digital
);

export const widow = new ArtInfo(
  "Widow",
  "A \"Quar-ink-tine\" piece I created during the 2020 pandemic. Inspired by the prompt 'widow'.",
  widowImage,
  Medium.digital
);

export const wizardnerd = new ArtInfo(
  "Flynn Design",
  "A character design of a wizard named Flynn Hallow. Flynn's magical abilities specialize within the school of Illusion. Has a familiar that is shaped like a baby seal.",
  wizardnerdImage,
  Medium.digital
);

export const ancientgolembg = new ArtInfo(
  "Ancient Golem Card",
  "A playable card designed for a video game.",
  ancientgolembgImage,
  Medium.digital
);

export const emperorpenguinbg = new ArtInfo(
  "Emperor Penguin Card",
  "A playable card designed for a video game.",
  emperorpenguinbgImage,
  Medium.digital
);

export const feelin = new ArtInfo(
  "Feelin'",
  'Album cover art created for the song "Feelin\'" by Shuprio on Spotify.',
  feelinImage,
  Medium.digital
);

export const flyingpenguinbg = new ArtInfo(
  "Flying Penguin Card",
  "A playable card designed for a video game.",
  flyingpenguinbgImage,
  Medium.digital
);

export const frostpenguinbg = new ArtInfo(
  "Frost Penguin Card",
  "A playable card designed for a video game.",
  frostpenguinbgImage,
  Medium.digital
);

export const hooligan = new ArtInfo(
  "Hooligan Logo",
  "A logo designed for the Hooligan Inline Bearings product sold by Neal Bearings.",
  hooliganImage,
  Medium.digital
);

export const kingshroombg = new ArtInfo(
  "King Shroom Card",
  "A playable card designed for a video game.",
  kingshroombgImage,
  Medium.digital
);

export const knuckles4 = new ArtInfo(
  "Knuckles Design",
  "A character concept design.",
  knuckles4Image,
  Medium.digital
);

export const lightninggolembg = new ArtInfo(
  "Lightning Golem Card",
  "A playable card designed for a video game.",
  lightninggolembgImage,
  Medium.digital
);

export const magepenguinbg = new ArtInfo(
  "Mage Penguin Card",
  "A playable card designed for a video game.",
  magepenguinbgImage,
  Medium.digital
);

export const pigs = new ArtInfo(
  "Sesame & Panda",
  "A stylized illustration of two guinea pigs named Sesame and Panda.",
  pigsImage,
  Medium.digital
);

export const ripper = new ArtInfo(
  "Ripper Design",
  "A character concept design.",
  ripperImage,
  Medium.digital
);

export const save_me = new ArtInfo(
  "Save Me",
  'Album cover art created for the song "Save Me" by Shuprio on Spotify.',
  save_meImage,
  Medium.digital
);

export const shuptees1 = new ArtInfo(
  "Anime Tees Design 1",
  "A T-shirt design featuring an original anime character I designed based on characters from popular anime shows.",
  shuptees1Image,
  Medium.digital
);

export const shuptees2 = new ArtInfo(
  "Anime Tees Design 2",
  "A T-shirt design featuring an original anime character I designed based on characters from popular anime shows.",
  shuptees2Image,
  Medium.digital
);

export const shuptees3 = new ArtInfo(
  "Anime Tees Design 3",
  "A T-shirt design featuring an original anime character I designed based on characters from popular anime shows.",
  shuptees3Image,
  Medium.digital
);

export const shuptees4 = new ArtInfo(
  "Anime Tees Design 4",
  "A T-shirt design featuring an original anime character I designed based on characters from popular anime shows.",
  shuptees4Image,
  Medium.digital
);

export const spiritpenguinbg = new ArtInfo(
  "Spirit Penguin Card",
  "A playable card designed for a video game.",
  spiritpenguinbgImage,
  Medium.digital
);

export const underwaterpenguinbg = new ArtInfo(
  "Underwater Penguin Card",
  "A playable card designed for a video game.",
  underwaterpenguinbgImage,
  Medium.digital
);

export const warriorpenguinbg = new ArtInfo(
  "Warrior Penguin Card",
  "A playable card designed for a video game.",
  warriorpenguinbgImage,
  Medium.digital
);

export const wychwood = new ArtInfo(
  "Wychwood Sketch",
  "A digital sketch used as promotional material for a real estate listing.",
  wychwoodImage,
  Medium.digital
);
