import "./Preview.scss";
import ArtInfo from "../../../component-lib/art/ArtInfo";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";

export default function ArtPreview(art: ArtInfo) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="Preview" onClick={handleShow}>
        <img src={art.previewImage ?? art.image} alt={art.title}></img>

        <div className="highlight bg-info"></div>
        <div className="overlay"></div>

        <div className="contents">
          <h1>{art.title}</h1>
        </div>
      </div>

      <Modal
        dialogClassName="modal-90w"
        size="xl"
        fullscreen="true"
        centered
        show={show}
        onHide={handleClose}
        id={"modal-preview-" + art.title}
      >
        <Modal.Header closeButton>
          <Modal.Title>{art.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className="contents">
            <Row className="justify-content-md-center">
              <Col md={7}>
                <Image fluid src={art.image} alt={art.title}></Image>
                <div className="caption">{art.formatOneline()}</div>
              </Col>
              <Col md className="my-auto">
                {art.description}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Back to Gallery
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
